import React from "react"
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="解決できる課題"
      pagedesc="イチモンが解決できる、採用活動の課題をご説明しています。"
      pagepath={location.pathname}
    />
    <main className="l-content">
      <div className="p-content">
        <div className="m-mv mv">
          <div className="mv__img-wrap">
            <Img fluid={data.key01.childImageSharp.fluid} alt="メインビジュアル画像" style={{ height: "100%", width: "100%" }} />
            <h2 className="mv__hdg">解決できる課題</h2>
          </div>
        </div>
        <div className="p-head head l-wrap">
          <h3 className="head__hdg--01">solutions</h3>
          <h4 className="head__desc--01">解決できる課題</h4>
        </div>

        <div className="p-list list l-wrap">
          <ul className="list__wrap">
            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                高い費用をかけて求人広告経由で採用したのに、すぐに退職される
              </h3>
              <p className="list__desc">
                求職者が本当に知りたい情報を求人広告上で提供できていなかった可能性があります。<br/>
                求人広告はどうしても情報量に制限があります。<br/>
                「求職者が知りたい情報を、もれなく大量に」掲載できる採用サイトを運用することでミスマッチを防ぎます。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                中小企業だから知名度がないし人が集まらない
              </h3>
              <p className="list__desc">
                大企業と中小企業では採用戦略は大きく異なります。<br/>
                またIndeedやGoogleお仕事検索のようなアグリゲーションサイトの登場で、採用戦略は大きな変化を求められています。
                アグリゲーションサイトのメリットと自社採用サイトの運用で、中小企業でも優秀な人材を獲得することが可能です。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                自社のカルチャー、社風とは合わない求職者ばかり応募してくる
              </h3>
              <p className="list__desc">
                御社の社風・文化を正しく伝えられていない可能性があります。<br/>
                求職者は、給与などの条件だけでなく社風や経営方針、中長期の経営方針など多角的に判断し、応募します。<br/>
                ミスマッチを防ぐためには、それらの情報を惜しみなく開示することが不可欠です。<br/>
                正しい戦略に基づいた自社採用サイトを運用することでこの問題を解消できます。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                採用コストをとにかく抑えたい
              </h3>
              <p className="list__desc">
                アグリゲーションサイトの台頭で従来型の求人媒体・採用メディアの存在感が薄れています。<br/>
                アグリゲーションサイトの活用 x 自社採用サイトの合わせ技で、優秀な人材をコストをかけることなく採用できます。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                長く働いてくれる人を採用したい
              </h3>
              <p className="list__desc">
                長く働いてもらうには、求職者の人生感と経営方針がマッチする必要があります<br/>
                ワークライフバランスを重視する求職者が、バリバリ働いてスキルを伸ばすことが得意な会社に入っても長続きしません。<br/>
                自社の経営方針・社風、働いて社員の声を自社採用サイトに掲載することで、求職者が大切にしている価値観と働き方が合致しているか判断できます。<br/>
                自社採用サイトでの情報を見て、応募してくれる求職者であれば長く働いてくれる可能性は高まります。<br/>
                そのためにも正しい情報の提供が不可欠です。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                ウェブサイト運営がどういうものかわからない
              </h3>
              <p className="list__desc">
                自社採用サイトでは、『求職者が知りたい情報を正確に大量に』提供することが大切です。<br/>
                また情報の鮮度も重要です。<br/>
                御社のありのままの情報を自社採用サイトに更新していく必要があります。<br/>
                ブログやYoutubeの活用で、求職者が知りたい情報を提供し続けることがウェブサイト運営の大原則です。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                最新の採用トレンドから取り残されている
              </h3>
              <p className="list__desc">
                アグリゲーションサイトの台頭など、採用業界は大きな変化が起こっています。<br/>
                しかし、日常の業務に追われる中小企業の経営者であれば、情報収集の時間が取れないのも納得できます。<br/>
                私どもは最新の採用業界のトレンドをいち早く提供し、自社採用サイトに反映させるスキームをご用意いたしております。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                SNSの運用方法がわからない
              </h3>
              <p className="list__desc">
                SNSの運用方法についてもサポートいたします。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                定量的なデータをもとに採用活動を最適化したい
              </h3>
              <p className="list__desc">
                Google Analyticsを始めとした解析ツールの活用をもとに毎月ウェブサイト運用のコンサルティングを行うサービスを提供しています。<br/>
                長期間の定量的計測と自社採用サイト運用は相性がよく、求職者のニーズに合った情報を提供できるようになります。<br/>
                ぜひ、ご検討ください。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                求職者がどのような情報を求めているのかわからない
              </h3>
              <p className="list__desc">
                求職者が求めている情報は、給与、休日などの条件面と経営方針、そしてどんな人が働いているかの３点が大きなポイントです。<br/>
                動的なウェブサイト戦略により求職者が求めている情報を正しく正確にわかりやすく伝えることが大切です。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                応募してもらうには働き方改革対策など必要か
              </h3>
              <p className="list__desc">
                これからの日本は少子高齢化が進み、毎週、労働人口が10,000人減ります。<br/>
                長期的な視野に立つと、これから人材獲得競争はますます加速すると思われます。<br/>
                せっかく採用した人材が辞められる事は最も避けるべき時代ですが、そもそも人が採用できにくい時代になります。<br/>
                働き方改革を推進することで、求職者が長く働きたくなるインセンティブを提供することができます。<br/>
                働き方改革への取り組みはこれからの人材獲得競争を見据えると欠かすことのできない課題です。<br />
                この問題に関してもコンサルティングできる準備ができております。
              </p>
            </li>

            <li className="list__item">
              <h3 className="list__hdg">
                <span className="list__icon">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                定性的なデータをもとに採用活動を最適化したい
              </h3>
              <p className="list__desc">
                Google Analyticsや、各種解析ツールを駆使自社採用サイトのコンテンツを最適化していきます。<br/>
                また5Gなどの技術革新を見据え、動画コンテンツの制作サポートも行います<br/>
                定性的なデータの取得、そしてそれを元にしたコンテンツ展開で採用活動を最適化していきます。
              </p>
            </li>
          </ul>
        </div>
      </div>{/* p-content */}
    </main>
  </Layout>
)

export const query = graphql`
  query {
    key01: file(relativePath: {eq: "solutions_mv01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`